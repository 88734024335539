@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  font-size: 15px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  letter-spacing: 1.25px;
  line-height: 1.75rem;
  margin: 0;
  margin-bottom: 1rem;
}

a {
  text-decoration: none;
}

strong {
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 1.75rem;
  margin: 0;
}

input {
  font-size: 0.5rem;
}

table {
  min-width: 90%;
}

th {
  font-size: 1rem;
  text-transform: uppercase;
  text-align: left;
  padding-bottom: 0.75rem;
}

td {
  color: #606060;
  padding-bottom: 0.5rem;
}

button {
  font-family: 'Raleway', sans-serif;
}

::selection {
  background: #2a2a2a;
}

ul {
  margin: 0.25rem 0 0.25rem 0;
  padding-left: 0.75rem;
}

ul li {
  list-style-type: '- ';
  color: #606060;
  line-height: 1.5rem;
}

small {
  color: #606060;
  line-height: 1.5rem;
  font-size: 0.85rem;
}

/* Accordion styling */
/**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/
.accordion {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  font-family: 'Raleway', sans-serif;
  margin-top: 2rem;
}

.accordion__item + .accordion__item {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion__button {
  background-color: #f4f4f4;
  color: #444;
  cursor: pointer;
  padding: 18px;
  text-align: left;
  border: none;
}

.accordion__button:hover {
  background-color: #ddd;
}

.accordion__button:after {
  display: inline-block;
  content: '';
  height: 10px;
  width: 10px;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-135deg);
  transition: all 0.3s;
  float: right;
  margin-top: 7px;
}

.accordion__button[aria-expanded='true']::after,
.accordion__button[aria-selected='true']::after {
  transform: rotate(45deg);
  margin-top: 0px;
}

[hidden] {
  display: none;
}

.accordion__panel {
  -webkit-animation: fadein 0.35s ease-in;
          animation: fadein 0.35s ease-in;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

